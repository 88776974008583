<template>
  <div id="app" class="relative w-[100vw] h-[100vh]" :style="{ 'background-color': bgColor.hex }">
    
    <!-- backgroung options -->
    <img v-show="bgType == 'image'" :src="bgImage" class="fixed w-full h-full object-cover" alt="image_bg">
    <video v-show="bgType == 'video'" :src="bgVideo.src" class="fixed inset-0 w-full h-full object-cover" 
        :muted="bgVideo.muted"
        :autoplay="bgVideo.autoplay"
        :loop="bgVideo.loop"
        :controls="bgVideo.controls"
        ref="player"
        ></video>
    
    <!-- content -->
    <div v-if="!lock" class="relative container h-full w-full pt-[30px] flex items-center justify-center">
      <div class="p-4 rounded-full -mt-[40%] space-y-2" :style="{ width: contentWidth+'px', color: txtColor.hex, 'font-size': txtSize+'px' }">

       <!-- Rectangle Veritcle Style -->
       <div v-show="style_selected == '0'" v-for="(d, i) in damage" :key="i" class="w-full">
          <div :style="{ 'margin-bottom': lineHeight+'px' }">
            <div class="w-full flex items-center justify-center">
              <!-- <div class="font-bold">{{ d.label_a | nn }}</div> -->
              <div class="font-bold uppercase text-center">{{ d.type }}</div>
              <!-- <div class="font-bold text-right">{{ d.label_b | nn }}</div> -->
            </div>
  
            <div class="relative flex items-center">
              <div class="font-bold absolute right-[102%] text-right mt-1" :style="{ color: nColor.hex }">
                <div v-if="i != 'monster'">{{ d.label_a | nn }}</div>
                <div v-if="i == 'monster'" class="flex items-center justify-between space-x-2">
                  <span>{{ d.label_a1 ? parseInt(d.label_a1) : '0' }}</span>
                  <span>/</span>
                  <span>{{ d.label_a2 ? d.label_a2 : '0' }}</span>
                  <span>/</span>
                  <span>{{ d.label_a3 ? d.label_a3 : '0' }}</span>
                </div> 
                
              </div>
              <!-- bar stat -->
              <div class="w-full relative mt-1 grid grid-cols-2 overflow-hidden" :style="{ height: barHeight+'px' }">
                <!-- bg -->
                <div class="absolute inset-0 z-0 " :style="{ background: barBg.hex }"></div>
  
                <!-- team a -->
                <div class="relative flex items-center justify-end">
                  <div class="absolute inset-0 z-0 " :style="{ background: barBg.hex }"></div>
                  <div class="relative text-white text-xs text-center flex items-center justify-center"
                  :style="{ width: parseInt(d.diff) ? (parseInt(d.a) > parseInt(d.b) ? d.diff +'%' : d.diffa +'%') : '0px', 
                  'background-color': teamAColor.hex,
                  'transition': 'width '+ inter_speed }"
                  >
                    <span v-show="d.label_a">
                      {{ parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%' }}
                    </span>
                  </div>
                </div>
                
                <!-- team b -->
                <div class="relative flex items-center justify-start">
                  <div class="absolute inset-0 z-0 " :style="{ background: barBg.hex }"></div>
                  <div class="relative text-xs text-center text-white shadow flex items-center justify-center
                  "
                  :style="{ width: parseInt(d.diff) ? (parseInt(d.a) < parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
                  'background-color': teamBColor.hex,
                  'transition': 'width '+ inter_speed }"
                  >
                    <span v-show="d.label_b" class="">{{ parseInt(d.b) > parseInt(d.a) ? d.diff+'%' : d.diffa+'%' }}</span>
                  </div>
                </div>
              </div>
              <div class="font-bold absolute left-[102%] text-left mt-1" :style="{ color: nColor.hex }">
                <div v-if="i != 'monster'">{{ d.label_b | nn }}</div>
                <div v-if="i == 'monster'" class="flex items-center justify-between space-x-2">
                  <span>{{ d.label_b1 ? parseInt(d.label_b1) : '0' }}</span>
                  <span>/</span>
                  <span>{{ d.label_b2 ? d.label_b2 : '0' }}</span>
                  <span>/</span>
                  <span>{{ d.label_b3 ? d.label_b3 : '0' }}</span>
                </div> 
              </div>
            </div>
          </div>
        </div>
        
        <!-- Classic Verticle Style -->
        <div v-show="style_selected == '2'" v-for="(d, i) in damage" :key="i" class="w-full">
          <div class="flex items-center justify-between">
            <div class="font-bold">{{ d.label_a | nn }}</div>
            <div class="font-bold uppercase text-center">{{ d.type }}</div>
            <div class="font-bold text-right">{{ d.label_b | nn }}</div>
          </div>
          <!-- bar stat -->
          <div class="relative mt-1 rounded-full overflow-hidden" :style="{ height: barHeight+'px' }">
            <!-- bg -->
            <div class="absolute inset-0 z-0 " :style="{ background: barBg.hex }"></div>

            <!-- team a -->
            <div class="absolute z-10 top-0 bottom-0 left-0 text-white text-xs text-center flex items-center justify-center"
            :style="{ width: parseInt(d.diff) ? (parseInt(d.a) > parseInt(d.b) ? d.diff +'%' : d.diffa +'%') : '0px', 
            'background-color': teamAColor.hex,
            'transition': 'width '+ inter_speed }"
            >
            <span v-show="d.label_a" class="">
              {{ parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%' }}
            </span>
            </div>
            
            <!-- team b -->
            <div class="absolute z-10 top-0 bottom-0 text-xs text-center text-white shadow flex items-center justify-center right-0"
            :style="{ width: parseInt(d.diff) ? (parseInt(d.a) < parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
            'background-color': teamBColor.hex,
            'transition': 'width '+ inter_speed }"
            >
            <span v-show="d.label_b" class="">{{ parseInt(d.b) > parseInt(d.a) ? d.diff+'%' : d.diffa+'%' }}</span>
            </div>
          </div>
        </div>

        <!-- Classic Skew Style -->
        <div v-show="style_selected == '3'" v-for="(d, i) in damage" :key="i" class="w-full">
          <div class="flex items-center justify-between">
            <div class="font-bold">{{ d.label_a | nn }}</div>
            <div class="font-bold uppercase text-center">{{ d.type }}</div>
            <div class="font-bold text-right">{{ d.label_b | nn }}</div>
          </div>
          <!-- bar stat -->
          <div class="relative mt-1 rounded-full overflow-hidden" :style="{ height: barHeight+'px' }">
            <!-- bg -->
            <div class="absolute inset-0 z-0 " :style="{ background: barBg.hex }"></div>

            <!-- team a -->
            <div class="absolute z-10 top-0 bottom-0 -left-[2%] text-white text-xs text-center 
            flex items-center justify-center -skew-x-[45deg]"
            :style="{ width: parseInt(d.diff) ? (parseInt(d.a) > parseInt(d.b) ? (parseInt(d.diff)+4) +'%' : (parseInt(d.diffa)+4) +'%') : '0px', 
            'background-color': teamAColor.hex,
            'transition': 'width '+ inter_speed }"
            >
            <span v-show="d.label_a" class="skew-x-[45deg]">
              {{ parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%' }}
            </span>
            </div>
            
            <!-- team b -->
            <div class="absolute z-10 top-0 bottom-0 text-xs text-center text-white shadow flex items-center justify-center -right-[2%] 
            -skew-x-[45deg]"
            :style="{ width: parseInt(d.diff) ? (parseInt(d.a) < parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
            'background-color': teamBColor.hex, 
            'transition': 'width '+ inter_speed  }"
            >
            <span v-show="d.label_b" class="skew-x-[45deg]">{{ parseInt(d.b) > parseInt(d.a) ? d.diff+'%' : d.diffa+'%' }}</span>
            </div>
          </div>
        </div>

        <!-- Rectangle Veritcle Style -->
        <div v-show="style_selected == '1'" v-for="(d, i) in damage" :key="i" class="w-full">
          <div :style="{ 'margin-bottom': lineHeight+'px' }">
            <div class="w-full flex items-center justify-center">
              <!-- <div class="font-bold">{{ d.label_a | nn }}</div> -->
              <div class="font-bold uppercase text-center">{{ d.type }}</div>
              <!-- <div class="font-bold text-right">{{ d.label_b | nn }}</div> -->
            </div>
  
            <div class="relative flex items-center">
              <div class="font-bold absolute right-[102%] text-right mt-1" :style="{ color: nColor.hex }">
                <div v-if="i != 'monster'">{{ d.label_a | nn }}</div>
                <div v-if="i == 'monster'" class="flex items-center justify-between space-x-2">
                  <span>{{ d.label_a1 ? parseInt(d.label_a1) : '0' }}</span>
                  <span>/</span>
                  <span>{{ d.label_a2 ? d.label_a2 : '0' }}</span>
                  <span>/</span>
                  <span>{{ d.label_a3 ? d.label_a3 : '0' }}</span>
                </div> 
                
              </div>
              <!-- bar stat -->
              <div class="w-full relative mt-1 overflow-hidden" :style="{ height: barHeight+'px' }">
                <!-- bg -->
                <div class="absolute inset-0 z-0 " :style="{ background: barBg.hex }"></div>
  
                <!-- team a -->
                <div class="absolute z-10 top-0 bottom-0 left-0 text-white text-xs text-center flex items-center justify-center"
                :style="{ width: parseInt(d.diff) ? (parseInt(d.a) > parseInt(d.b) ? d.diff +'%' : d.diffa +'%') : '0px', 
                'background-color': teamAColor.hex,
                'transition': 'width '+ inter_speed }"
                >
                <span v-show="d.label_a">
                  {{ parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%' }}
                </span>
                </div>
                
                <!-- team b -->
                <div class="absolute z-10 top-0 bottom-0 text-xs text-center text-white shadow flex items-center justify-center right-0 
                "
                :style="{ width: parseInt(d.diff) ? (parseInt(d.a) < parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
                'background-color': teamBColor.hex,
                'transition': 'width '+ inter_speed }"
                >
                <span v-show="d.label_b" class="">{{ parseInt(d.b) > parseInt(d.a) ? d.diff+'%' : d.diffa+'%' }}</span>
                </div>
              </div>
              <div class="font-bold absolute left-[102%] text-left mt-1" :style="{ color: nColor.hex }">
                <div v-if="i != 'monster'">{{ d.label_b | nn }}</div>
                <div v-if="i == 'monster'" class="flex items-center justify-between space-x-2">
                  <span>{{ d.label_b1 ? parseInt(d.label_b1) : '0' }}</span>
                  <span>/</span>
                  <span>{{ d.label_b2 ? d.label_b2 : '0' }}</span>
                  <span>/</span>
                  <span>{{ d.label_b3 ? d.label_b3 : '0' }}</span>
                </div> 
              </div>
            </div>
          </div>
        </div>

        <!-- Rectangle Veritcle Style -->
        <div v-show="style_selected == '4'" v-for="(d, i) in damage" :key="i" class="w-full">
          <div class="flex items-center justify-between">
            <div class="font-bold">{{ d.label_a | nn }}</div>
            <div class="font-bold uppercase text-center">{{ d.type }}</div>
            <div class="font-bold text-right">{{ d.label_b | nn }}</div>
          </div>
          <!-- bar stat -->
          <div class="relative mt-1 overflow-hidden" :style="{ height: barHeight+'px' }">
            <!-- bg -->
            <div class="absolute inset-0 z-0 " :style="{ background: barBg.hex }"></div>

            <!-- team a -->
            <div class="absolute z-10 top-0 bottom-0 left-0 text-white text-xs text-center flex items-center justify-center"
            :style="{ width: parseInt(d.diff) ? (parseInt(d.a) > parseInt(d.b) ? d.diff +'%' : d.diffa +'%') : '0px', 
            'background-color': teamAColor.hex,
            'transition': 'width '+ inter_speed }"
            >
            <span v-show="d.label_a">
              {{ parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%' }}
            </span>
            </div>
            
            <!-- team b -->
            <div class="absolute z-10 top-0 bottom-0 text-xs text-center text-white shadow flex items-center justify-center right-0 
            "
            :style="{ width: parseInt(d.diff) ? (parseInt(d.a) < parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
            'background-color': teamBColor.hex,
            'transition': 'width '+ inter_speed }"
            >
            <span v-show="d.label_b" class="">{{ parseInt(d.b) > parseInt(d.a) ? d.diff+'%' : d.diffa+'%' }}</span>
            </div>
          </div>
        </div>

        <!-- Classic Skew Style -->
        <div v-show="style_selected == '5'" v-for="(d, i) in damage" :key="i" class="w-full">
          <div class="flex items-center justify-between">
            <div class="font-bold">{{ d.label_a | nn }}</div>
            <div class="font-bold uppercase text-center">{{ d.type }}</div>
            <div class="font-bold text-right">{{ d.label_b | nn }}</div>
          </div>
          <!-- bar stat -->
          <div class="relative mt-1 overflow-hidden" :style="{ height: barHeight+'px' }">
            <!-- bg -->
            <div class="absolute inset-0 z-0 " :style="{ background: barBg.hex }"></div>

            <!-- team a -->
            <div class="absolute z-10 top-0 bottom-0 -left-[2%] text-white text-xs text-center flex items-center justify-center -skew-x-[45deg]"
            :style="{ width: parseInt(d.diff) ? (parseInt(d.a) > parseInt(d.b) ? (parseInt(d.diff)+4) +'%' : (parseInt(d.diffa)+4) +'%') : '0px', 
            'background-color': teamAColor.hex,
            'transition': 'width '+ inter_speed }"
            >
            <span v-show="d.label_a" class="skew-x-[45deg]">
              {{ parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%' }}
            </span>
            </div>
            
            <!-- team b -->
            <div class="absolute z-10 top-0 bottom-0 text-xs text-center text-white shadow flex items-center justify-center -right-[2%] 
            -skew-x-[45deg]"
            :style="{ width: parseInt(d.diff) ? (parseInt(d.a) < parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
            'background-color': teamBColor.hex,
            'transition': 'width '+ inter_speed }"
            >
            <span v-show="d.label_b" class="skew-x-[45deg]">{{ parseInt(d.b) > parseInt(d.a) ? d.diff+'%' : d.diffa+'%' }}</span>
            </div>
          </div>
        </div>

        <!-- CV Rounded style -->
        <div v-show="style_selected == '6'" class="w-full flex items-center space-x-2 justify-between">
          
            <div v-for="(d, i) in damage" :key="i" class="w-full relative">
              <div class="flex items-end space-x-2 justify-center h-[300px] -mt-[100px]">

                <div class="w-[30px] h-[100%] relative flex items-end justify-center">
                  <div class="absolute inset-0 border rounded-lg" :style="{ background: barBg.hex }">
                    <span class="absolute bottom-1 inset-x-0 text-center">0</span>
                  </div>
                  <!-- team a -->
                  <div :style="{ height: parseInt(d.diff) ? (parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
                  'background-color': teamAColor.hex, 
                  width: (barHeight*2)+'px',
                  'transition': 'height '+ inter_speed
                   }"
                  class="relative flex items-center justify-center rounded-lg">
                    <div class="absolute -top-[20px] right-0 font-bold">
                      <span v-show="d.label_a">{{ d.label_a | nn }}</span>
                    </div>
                    <div class="text-[8px] text-center text-white font-bold">
                      <span v-show="d.label_a">{{ parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%' }}</span>
                    </div>
                  </div>
                </div>

                <div class="w-[30px] h-[100%] relative flex items-end justify-center">

                  <div class="absolute inset-0 border rounded-lg " :style="{ background: barBg.hex }">
                    <span class="absolute bottom-1 inset-x-0 text-center">0</span>
                  </div>
                  <!-- team b -->
                  <div :style="{ height: parseInt(d.diff) ? (parseInt(d.a) < parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
                  'background-color': teamBColor.hex, 
                  width: (barHeight*2)+'px',
                  'transition': 'height '+ inter_speed }"
                  class="relative w-[30px] flex items-center justify-center rounded-lg">
                    <div class="absolute -top-[20px] left-0 font-bold">
                      <span v-show="d.label_b">{{ d.label_b | nn }}</span>
                    </div>
                    <div class="text-[8px] text-center text-white font-bold">
                      <span v-show="d.label_b">{{ parseInt(d.b) > parseInt(d.a) ? d.diff+'%' : d.diffa+'%' }}</span>
                    </div>
                  </div>
                </div>

              </div>

              <div class="absolute top-[100%] w-full text-center uppercase font-bold">
                {{ d.type }}
              </div>
            </div>

        </div>

        <!-- CV Square style -->
        <div v-show="style_selected == '7'" class="w-full flex items-center space-x-2 justify-between">
          
          <div v-for="(d, i) in damage" :key="i" class="w-full relative">
            <div class="flex items-end space-x-2 justify-center h-[300px] -mt-[100px]">

              <div class="w-[30px] h-[100%] relative flex items-end justify-center">
                <div class="absolute inset-0 border" :style="{ background: barBg.hex }">
                  <span class="absolute bottom-1 inset-x-0 text-center">0</span>
                </div>
                <!-- team a -->
                <div :style="{ height: parseInt(d.diff) ? (parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
                'background-color': teamAColor.hex, 
                width: (barHeight*2)+'px',
                'transition': 'height '+ inter_speed }"
                class="relative flex items-center justify-center">
                  <div class="absolute -top-[20px] right-0 font-bold">
                    <span v-show="d.label_a">{{ d.label_a | nn }}</span>
                  </div>
                  <div class="text-[8px] text-center text-white font-bold">
                    <span v-show="d.label_a">{{ parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%' }}</span>
                  </div>
                </div>
              </div>

              <div class="w-[30px] h-[100%] relative flex items-end justify-center">

                <div class="absolute inset-0 border  " :style="{ background: barBg.hex }">
                  <span class="absolute bottom-1 inset-x-0 text-center">0</span>
                </div>
                <!-- team b -->
                <div :style="{ height: parseInt(d.diff) ? (parseInt(d.a) < parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
                'background-color': teamBColor.hex,
                 width: (barHeight*2)+'px',
                 'transition': 'height '+ inter_speed }"
                class="relative w-[30px] flex items-center justify-center">
                  <div class="absolute -top-[20px] left-0 font-bold">
                    <span v-show="d.label_b">{{ d.label_b | nn }}</span>
                  </div>
                  <div class="text-[8px] text-center text-white font-bold">
                    <span v-show="d.label_b">{{ parseInt(d.b) > parseInt(d.a) ? d.diff+'%' : d.diffa+'%' }}</span>
                  </div>
                </div>
              </div>

            </div>

            <div class="absolute top-[100%] w-full text-center uppercase font-bold">
              {{ d.type }}
            </div>
          </div>

        </div>

        <!-- Split Verticle style -->
        <div v-show="style_selected == '8'" class="w-full space-y-4">
          <div v-for="(d, i) in damage" :key="i">
            <div class="text-center font-bold uppercase">{{ d.type }}</div>
            <div class="w-full grid grid-cols-2" :style="{ height: (barHeight*2)+'px' }">
              <!-- team a -->
              <div class="w-full relative">
                <div class="absolute inset-y-0 w-full right-0 border rounded-l-lg " :style="{ background: barBg.hex }"></div>
                <div class="absolute inset-y-0 right-0 flex items-center justify-center rounded-l-lg" 
                :style="{ width: parseInt(d.diff) ? (parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
                'background-color': teamAColor.hex,
                'transition': 'width '+ inter_speed }">
                  <span v-show="d.label_a" class="text-white">{{ parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%' }}</span>
                  <span class="absolute right-[105%] font-bold mr-2">{{ d.label_a | nn }}</span>
                </div>
              </div>
              <!-- team b -->
              <div class="w-full relative">
                <div class="absolute inset-y-0 w-full left-0 border rounded-r-lg " :style="{ background: barBg.hex }"></div>
                <div class="absolute inset-y-0 left-0 flex items-center justify-center rounded-r-lg" 
                :style="{ width: parseInt(d.diff) ? (parseInt(d.a) < parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
                'background-color': teamBColor.hex,
                'transition': 'width '+ inter_speed } ">
                  <span v-show="d.label_b" class="text-white">{{ parseInt(d.b) > parseInt(d.a) ? d.diff+'%' : d.diffa+'%' }}</span>
                  <span class="absolute left-[105%] font-bold ml-2">{{ d.label_b | nn }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Split Square style -->
        <div v-show="style_selected == '9'" class="w-full space-y-4">
          <div v-for="(d, i) in damage" :key="i">
            <div class="text-center font-bold uppercase">{{ d.type }}</div>
            <div class="w-full grid grid-cols-2" :style="{ height: (barHeight*2)+'px' }">
              <!-- team a -->
              <div class="w-full relative">
                <div class="absolute inset-y-0 w-full right-0 border  " :style="{ background: barBg.hex }"></div>
                <div class="absolute inset-y-0 right-0 flex items-center justify-center" 
                :style="{ width: parseInt(d.diff) ? (parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
                'background-color': teamAColor.hex,
                'transition': 'width '+ inter_speed }">
                  <span v-show="d.label_a" class="text-white">{{ parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%' }}</span>
                  <span class="absolute right-[105%] font-bold mr-2">{{ d.label_a | nn }}</span>
                </div>
              </div>
              <!-- team b -->
              <div class="w-full relative">
                <div class="absolute inset-y-0 w-full left-0 border " :style="{ background: barBg.hex }"></div>
                <div class="absolute inset-y-0 left-0 flex items-center justify-center" 
                :style="{ width: parseInt(d.diff) ? (parseInt(d.a) < parseInt(d.b) ? d.diff+'%' : d.diffa+'%') : '0px', 
                'background-color': teamBColor.hex,
                'transition': 'width '+ inter_speed } ">
                  <span v-show="d.label_b" class="text-white">{{ parseInt(d.b) > parseInt(d.a) ? d.diff+'%' : d.diffa+'%' }}</span>
                  <span class="absolute left-[105%] font-bold ml-2">{{ d.label_b | nn }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Split Skew style -->
        <div v-show="style_selected == '10'" class="w-full space-y-4">
          <div v-for="(d, i) in damage" :key="i">
            <div class="text-center font-bold uppercase">{{ d.type }}</div>
            <div class="w-full grid grid-cols-2" :style="{ height: (barHeight*2)+'px' }">
              <!-- team a -->
              <div class="w-full relative ">
                <div class="absolute inset-y-0 w-full right-0 border  " :style="{ background: barBg.hex }"></div>
                <div class="absolute inset-y-0 -right-[12%] flex items-center justify-center -skew-x-[45deg]" 
                :style="{ width: parseInt(d.diff) ? (parseInt(d.a) > parseInt(d.b) ? (parseInt(d.diff)+12)+'%' : (parseInt(d.diffa)+12)+'%') : '0px', 
                'background-color': teamAColor.hex,
                'transition': 'width '+ inter_speed }">
                  <span v-show="d.label_a" class="text-white skew-x-[45deg]">{{ parseInt(d.a) > parseInt(d.b) ? d.diff+'%' : d.diffa+'%' }}</span>
                  <span class="absolute right-[105%] font-bold skew-x-[45deg]">{{ d.label_a | nn }}</span>
                </div>
              </div>
              <!-- team b -->
              <div class="w-full relative overflow-hidden">
                <div class="absolute inset-y-0 w-full left-0 border " :style="{ background: barBg.hex }"></div>
                <div class="absolute inset-y-0 -left-[12%] flex items-center justify-center -skew-x-[45deg]" 
                :style="{ width: parseInt(d.diff) ? (parseInt(d.a) < parseInt(d.b) ? (parseInt(d.diff)+12)+'%' : (parseInt(d.diffa)+12)+'%') : '0px', 
                'background-color': teamBColor.hex,
                'transition': 'width '+ inter_speed } ">
                  <span v-show="d.label_b" class="text-white skew-x-[45deg]">{{ parseInt(d.b) > parseInt(d.a) ? d.diff+'%' : d.diffa+'%' }}</span>
                  <span class="absolute left-[105%] font-bold skew-x-[45deg]">{{ d.label_b | nn }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- footer -->
    <div v-if="!lock" class="absolute bottom-0 inset-x-0">

      <!-- video controls -->
      <div v-show="bgVideo.src" class="container px-4 py-2">
        <div class="w-full grid grid-cols-6 gap-4">
          <div></div>
          <div></div>
          <div v-show="!playing" @click="play()" class="flex items-center justify-center cursor-pointer text-sm rounded-lg bg-blue-400 hover:bg-blue-500 text-white">
            <i class="i-play"></i> Play
          </div>
          <div v-show="playing" @click="pause()" class="flex items-center justify-center cursor-pointer text-sm rounded-lg bg-red-400 hover:bg-red-500 text-white">
            <i class="i-pause"></i> Pause
          </div>
          <div v-show="!bgVideo.muted" @click="mute()" class="flex items-center justify-center cursor-pointer text-sm rounded-lg bg-gray-400 hover:bg-gray-500 text-white">
            <i class="i-volume-off"></i> Mute
          </div>
          <div v-show="bgVideo.muted" @click="unmute()" class="flex items-center justify-center cursor-pointer text-sm rounded-lg bg-gray-500 hover:bg-gray-400 text-white">
            <i class="i-volume-up"></i> Unmute
          </div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div class="relative container px-4 py-2 bg-gray-200 text-black select-none">

        <!-- toggle hide/show settings -->
        <div @click="hide_settings = !hide_settings" class="absolute bottom-[100%] right-0 bg-black px-4 py-1 text-sm rounded text-white cursor-pointer">
          {{ hide_settings ? 'Show' : 'Hide' }} Settings
        </div>

        <div v-show="!hide_settings" class="w-full grid grid-cols-6 gap-4">
          
          <div class="relative">
            <div class="text-xs flex space-x-2 cursor-pointer">Background Color</div>
            <div @click="show_bgcolorpicker = ! show_bgcolorpicker" class="flex items-center gap-4 cursor-pointer hover:bg-gray-300 px-2 rounded-lg">
              <div class="w-4 h-4 rounded-full" :style="{ 'background-color': bgColor.hex }"></div> {{ bgColor.hex }}
            </div>
            <div v-show="show_bgcolorpicker" class="absolute z-50 -top-[150px] -left-[230px] text-right">
              <span @click="show_bgcolorpicker=false" class="px-2 py-1 rounded-lg bg-black cursor-pointer text-white mb-1">Close</span>
              <colour-picker v-model="bgColor" :value="bgColor.hex" picker="chrome"></colour-picker>
            </div>
          </div>

          <div>
            <div class="text-xs uppercase">Number Indicator</div>
            <div>
              <!-- <input type="file" accept="image/*" ref="imageFile" @change="uploadImage" class="text-xs text-black"> -->
              <div class="w-full relative">
              <div @click="n_show = !n_show" class="flex items-center cursor-pointer hover:bg-gray-100 px-2 rounded">
                <div class="w-4 h-4 rounded-full mr-4" :style="{ 'background-color': nColor.hex }"></div> {{  nColor.hex }} 
              </div>
              <div v-show="n_show" class="absolute z-50 -top-[150px] -right-[200px] text-right">
                <span @click="n_show=false" class="px-2 py-1 rounded-lg bg-black cursor-pointer text-white mb-1">Close</span>
                <colour-picker v-model="nColor" :value="nColor.hex" picker="chrome"></colour-picker>
              </div>
            </div>
            </div>
          </div>

          

          <div class="overflow-hidden">
            <div class="text-xs uppercase mb-[1px]">Line Width</div>
            <div class="w-full relative flex items-center">
              <input type="number" min="10" v-model="contentWidth" class="text-sm px-2 rounded-lg w-full mr-4">
              <i @click="contentWidth--" class="i-down-big"></i>
              <i @click="contentWidth++" class="i-up-big"></i>
            </div>
            <!-- <select name="bg" v-model="style_selected" class="w-full rounded text-sm border-gray-500 border text-black px-2">
                <option v-for="style in styles" :key="style.name" :value="style.v">{{ style.name }}</option>
            </select> -->
          </div>

          <div class="overflow-hidden">
            <div class="text-xs uppercase">Line Height</div>
            <div class="w-full relative flex items-center">
              <input type="number" min="10" v-model="lineHeight" class="text-sm px-2 rounded-lg w-full mr-4">
              <i @click="lineHeight--" class="i-down-big"></i>
              <i @click="lineHeight++" class="i-up-big"></i>
            </div>
            <!-- <div class="space-x-2">
              <span @click="contentWidth = 500" class="px-2 text-xs rounded-lg cursor-pointer border border-gray-600 hover:bg-gray-100">Default</span>
              <span @click="contentWidth = 900" class="px-2 text-xs rounded-lg cursor-pointer border border-gray-600 hover:bg-gray-100">Large</span>
            </div> -->
          </div>

          <div>
            <div class="space-y-2">
              
              <div class="relative">
                <div @click="show_barColorpicker = ! show_barColorpicker" class="text-xs flex space-x-2 cursor-pointer">Bar Background 
                  <div class="w-4 h-4 rounded-full ml-4" :style="{ 'background-color': barBg.hex }"></div>
                </div>
                <div v-show="show_barColorpicker" class="absolute z-50 -top-[150px] -left-[230px] text-right">
                  <span @click="show_barColorpicker=false" class="px-2 py-1 rounded-lg bg-black cursor-pointer text-white mb-1">Close</span>
                  <colour-picker v-model="barBg" :value="barBg.hex" picker="chrome"></colour-picker>
                </div>
              </div>

              <div>
                <span class="text-xs">Text Speed</span> <select v-model="speed" class="px-2 text-sm rounded-lg bg-white">
                <option value="1.3">slow</option>
                <option value="1.5">Normal</option>
                <option value="2">Fast</option>
                </select>
              </div>
            </div>
          </div>

          <div class="overflow-hidden">
            <div></div>
            <div @click="resetSetting()" class="mt-4 text-xs uppercase bg-red-500 hover:bg-red-600 rounded-lg text-white text-center">Reset Setting</div>
          </div>

        </div>

      </div>
      <div class="container p-4 bg-gray-300 text-black">
        <!-- config -->
        <div v-show="!hide_settings" class="w-full grid grid-cols-6 gap-4 mb-4">
          
          <div>
            <div class="text-xs uppercase mb-[1px]">Label Color</div>
            <div class="w-full relative">
              <div @click="tc_show = !tc_show" class="flex items-center cursor-pointer hover:bg-gray-100 px-2 rounded">
                <div class="w-4 h-4 rounded-full mr-4" :style="{ 'background-color': txtColor.hex }"></div> {{  txtColor.hex }} 
              </div>
              <div v-show="tc_show" class="absolute z-50 -top-[150px] -right-[200px] text-right">
                <span @click="tc_show=false" class="px-2 py-1 rounded-lg bg-black cursor-pointer text-white mb-1">Close</span>
                <colour-picker v-model="txtColor" :value="txtColor.hex" picker="chrome"></colour-picker>
              </div>
            </div>
          </div>

          <div class="overflow-hidden">
            <div class="text-xs uppercase">Label Font Size</div>
            <div class="w-full relative flex items-center">
              <input type="number" min="10" v-model="txtSize" class="text-sm px-2 rounded-lg w-full mr-4">
              <i @click="txtSize--" class="i-down-big"></i>
              <i @click="txtSize++" class="i-up-big"></i>
            </div>
          </div>

          <div class="overflow-hidden">
            <div class="text-xs uppercase">Bar Height/Width</div>
            <div class="w-full relative flex items-center">
              <input type="number" min="10" v-model="barHeight" class="text-sm px-2 rounded-lg w-full mr-4">
              <i @click="barHeight--" class="i-down-big"></i>
              <i @click="barHeight++" class="i-up-big"></i>
            </div>
          </div>

          <div class="overflow-hidden">
            <div class="text-xs uppercase mb-[1px]">Bar Speed</div>
            <div class="w-full relative">
              <select v-model="inter_speed" class="px-2 text-sm rounded-lg w-full">
                <option value="0.5s">0.5s</option>
                <option value="1s">1s</option>
                <option value="1.5s">1.5s</option>
              </select>
            </div>
          </div>

          <div>
            <div class="text-xs uppercase">Team A Bar Color</div>
            <div class="w-full relative">
              <div @click="tac_show = !tac_show" class="flex items-center cursor-pointer hover:bg-gray-100 px-2 rounded">
                <div class="w-4 h-4 rounded-full mr-4" :style="{ 'background-color': teamAColor.hex }"></div> {{  teamAColor.hex }} 
              </div>
              <div v-show="tac_show" class="absolute z-50 -top-[150px] -left-[230px] text-right">
                <span @click="tac_show=false" class="px-2 py-1 rounded-lg bg-black cursor-pointer text-white mb-1">Close</span>
                <colour-picker v-model="teamAColor" :value="teamAColor.hex" picker="chrome"></colour-picker>
              </div>
            </div>
          </div>

          <div>
            <div class="text-xs uppercase">Team B Bar Color</div>
            <div class="w-full relative">
              <div @click="tbc_show = !tbc_show" class="flex items-center cursor-pointer hover:bg-gray-100 px-2 rounded">
                <div class="w-4 h-4 rounded-full mr-4" :style="{ 'background-color': teamBColor.hex }"></div> {{  teamBColor.hex }} 
              </div>
              <div v-show="tbc_show" class="absolute z-50 -top-[150px] -left-[230px] text-right">
                <span @click="tbc_show=false" class="px-2 py-1 rounded-lg bg-black cursor-pointer text-white mb-1">Close</span>
                <colour-picker v-model="teamBColor" :value="teamBColor.hex" picker="chrome"></colour-picker>
              </div>
            </div>
          </div>

        </div>
        <!-- stats -->
        <div class="w-full grid grid-cols-4 gap-2">
          
          <div class="space-y-1">
            <span class="text-sm">Label:</span> <input type="text" v-model="damage.dealth.type" class="text-sm px-2 rounded-lg">
            <div class="text-xs space-x-2">
              <div class="font-bold" :style="{ color: teamAColor.hex }">Team A</div>
              <input type="number" v-model="damage.dealth.a" min="0" placeholder="0" class="text-sm rounded-lg px-2" :disabled="btnSummit">
            </div>
            <div class="text-xs space-x-2">
              <div class=" font-bold" :style="{ color: teamBColor.hex }">Team B</div>
              <input type="number" v-model="damage.dealth.b" min="0" placeholder="0" class="text-sm rounded-lg px-2" :disabled="btnSummit">
            </div>
          </div>

          <div class="space-y-1">
            <span class="text-sm">Label:</span> <input type="text" v-model="damage.taken.type" class="text-sm px-2 rounded-lg">
            <div class="text-xs space-x-2">
              <div class="font-bold" :style="{ color: teamAColor.hex }">Team A</div>
              <input type="number" v-model="damage.taken.a" min="0" placeholder="0" class="text-sm rounded-lg px-2" :disabled="btnSummit">
            </div>
            <div class="text-xs space-x-2">
              <div class=" font-bold" :style="{ color: teamBColor.hex }">Team B</div>
              <input type="number" v-model="damage.taken.b" min="0" placeholder="0" class="text-sm rounded-lg px-2" :disabled="btnSummit">
            </div>
          </div>

          <div class="space-y-1">
            <span class="text-sm">Label:</span> <input type="text" v-model="damage.turret.type" class="text-sm px-2 rounded-lg">
            <div class="text-xs space-x-2">
              <div class="font-bold" :style="{ color: teamAColor.hex }">Team A</div>
              <input type="number" v-model="damage.turret.a" min="0" placeholder="0" class="text-sm rounded-lg px-2" :disabled="btnSummit">
            </div>
            <div class="text-xs space-x-2">
              <div class=" font-bold" :style="{ color: teamBColor.hex }">Team B</div>
              <input type="number" v-model="damage.turret.b" min="0" placeholder="0" class="text-sm rounded-lg px-2" :disabled="btnSummit">
            </div>
          </div>

          <div class="space-y-1">
            <span class="text-sm">Label:</span> <input type="text" v-model="damage.monster.type" class="text-sm px-2 rounded-lg">
            <div class="text-xs space-x-2">
              <div class="font-bold" :style="{ color: teamAColor.hex }">Team A</div>
              <div class="flex items-center space-x-1">
                <input type="number" v-model="damage.monster.a1" min="0" placeholder="0" style="width: 70px;" class=" text-sm rounded-lg px-2" :disabled="btnSummit">
                <input type="number" v-model="damage.monster.a2" min="0" placeholder="0" style="width: 70px;" class=" text-sm rounded-lg px-2" :disabled="btnSummit">
                <input type="number" v-model="damage.monster.a3" min="0" placeholder="0" style="width: 70px;" class=" text-sm rounded-lg px-2" :disabled="btnSummit">
              </div>
            </div>
            <div class="text-xs space-x-2">
              <div class=" font-bold" :style="{ color: teamBColor.hex }">Team B</div>
              <div class="flex items-center space-x-1">
                <input type="number" v-model="damage.monster.b1" min="0" placeholder="0" style="width: 70px;" class=" text-sm rounded-lg px-2" :disabled="btnSummit">
                <input type="number" v-model="damage.monster.b2" min="0" placeholder="0" style="width: 70px;" class=" text-sm rounded-lg px-2" :disabled="btnSummit">
                <input type="number" v-model="damage.monster.b3" min="0" placeholder="0" style="width: 70px;" class=" text-sm rounded-lg px-2" :disabled="btnSummit">
              </div>
            </div>
          </div>

        </div>
        <div class="grid grid-cols-1 gap-2 mt-4">
          <div class="grid grid-cols-2 gap-2">
            <div v-show="btnSummit" @click="resetValue()" class="py-1 text-center rounded-lg cursor-pointer bg-red-500 text-white hover:bg-red-600">Reset Data</div>
            <div v-show="btnSummit" @click="reuseValue()" class="py-1 text-center rounded-lg cursor-pointer bg-yellow-500 text-white hover:bg-yellow-600">Reuse Data</div>
          </div>
          <div @click="submitStats()" v-show="!btnSummit"
          class="bg-blue-500 p-1 rounded-lg text-center text-white hover:bg-blue-600 cursor-pointer">Submit Stats</div>
        </div>
      </div>
    </div>

    <div @click="show_barColorpicker = false" v-show="show_barColorpicker" class="absolute inset-0 bg-tranparent"></div>
    <div @click="tac_show = false" v-show="tac_show" class="absolute inset-0 bg-tranparent"></div>
    <div @click="tbc_show = false" v-show="tbc_show" class="absolute inset-0 bg-tranparent"></div>
    <div @click="tc_show = false" v-show="tc_show" class="absolute inset-0 bg-tranparent"></div>
    <div @click="show_bgcolorpicker = false" v-show="show_bgcolorpicker" class="absolute inset-0 bg-tranparent"></div>

    <div v-show="lock" class="absolute inset-0 bg-gray-900 flex items-center justify-center">
        <div class="bg-gray-800 p-6 rounded-lg text-white">
          <input type="text" v-model="passcode" @keydown.enter="verify()" placeholder="Please enter the passcode" class="rounded p-2 text-black">
          <div v-if="incorrect" class="text-red-500 text-sm">Passcode is incorrect</div> 
          <div @click="verify()" class="text-center p-2 my-2 bg-gray-900">Enter</div>
        </div>
    </div>
    
  </div>
</template>

<script>

import { Chrome } from 'vue-color'
export default {
  components: {
    'colour-picker': Chrome,
  },
  data() {
    return {
      lock: false,
      passcode: '', 
      incorrect: false,
      hide_settings: false,
      message: 'Hello world',
      bgColor: {
        hex: '#FFFFFF'
      },
      show_bgcolorpicker: false,
      bgImage: null,
      playing: false,
      bgType: '',
      bgVideo: {
        src: '',
        muted: false,
        loop: true,
        autoplay: false,
        controls: false,
      },

      style_selected: '0',
      styles: [
      { name: 'Rectangle', v: '0' },
        { name: 'Rectangle', v: '1' },
        { name: 'Rounded Verticle', v: '2' },
        { name: 'Rounded Skew', v: '3' },
        
        { name: 'Rectangle Verticle', v: '4' },
        { name: 'Rectangle Skew', v: '5' },
        { name: 'Column Verticle Rounded', v: '6' },
        { name: 'Column Verticle Square', v: '7' },
        { name: 'Split Verticle', v: '8' },
        { name: 'Split Square', v: '9' },
        { name: 'Split Skew', v: '10' }
      ],

      contentWidth: '500',
      btnSummit: false,

      n_show: false,
      nColor: {
        hex: '#000000'
      },

      txtColor: {
        hex: '#000000'
      },
      tc_show: false,
      txtSize: '14',

      lineHeight: '10',
      barHeight: '14',
      barSpeed: '1',
      barBg: {
        hex: '#CCCCCC'
      },

      speed: '1.5',
      inter_speed: '1s',

      teamAColor: {
        hex: '#FF0000'
      },
      teamBColor: {
        hex: '#0000FF'
      },
      tac_show: false,
      tbc_show: false,
      show_barColorpicker: false,
      
      damage: {
        dealth: {
          type: 'damage dealth',
          a: '',
          b: '',
          label_a: 0,
          label_b: 0,
          diff: '0',
          diffa: '0'
        },
        taken: {
          type: 'damage taken',
          a: '',
          b: '',
          label_a: 0,
          label_b: 0,
          diff: '0',
          diffa: '0'
        },
        turret: {
          type: 'damage to turret',
          a: '',
          b: '',
          label_a: 0,
          label_b: 0,
          diff: '0',
          diffa: '0'
        },
        monster: {
          type: 'Turtle / Lord / Turret',
          a: '',
          a1: '',
          a2: '',
          a3: '',
          b: '',
          b1: '',
          b2: '',
          b3: '',
          label_a: 0,
          label_a1: 0,
          label_a2: 0,
          label_a3: 0,
          label_b: 0,
          diff: '0',
          diffa: '0'
        }
      }
    }
  },
  methods: {
    uploadImage(e) {
      this.resetBg('video')
      this.bgType = "image"

      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e =>{
          this.bgImage = e.target.result;
      };
      
    },
    uploadVideo(e) {
        this.resetBg('image')
        this.bgType = "video"

        const vdo = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(vdo);
        reader.onload = e =>{
            this.bgVideo.src = e.target.result;
        };
    },
    uploadColor() {
      this.resetBg('iv')
      this.bgType = "color";
    },
    resetBg(type) {
      if (type == 'image') 
      {
        this.bgImage = null
        this.$refs.imageFile.value = null
      }
      if (type == 'video') {
        this.bgVideo.src = ''
        this.$refs.videoFile.value = null
      }
      if (type == 'iv') {
        this.bgImage = null
        this.$refs.imageFile.value = null
        this.bgVideo.src = ''
        this.$refs.videoFile.value = null
      }
      if (type == 'all') {
        this.contentWidth = 500
        this.bgColor.hex = '#FFFFFF' 
        this.bgImage = null
        this.$refs.imageFile.value = null
        this.bgVideo.src = ''
        this.$refs.videoFile.value = null
      }
    },
    resetValue() {
      this.damage.dealth.a = ''
      this.damage.dealth.b = ''
      this.damage.dealth.label_a = ''
      this.damage.dealth.label_b = ''
      this.damage.dealth.diff = 0
      this.damage.dealth.diffa = 0

      this.damage.taken.a = ''
      this.damage.taken.b = ''
      this.damage.taken.label_a = ''
      this.damage.taken.label_b = ''
      this.damage.taken.diff = 0
      this.damage.taken.diffa = 0

      this.damage.turret.a = ''
      this.damage.turret.b = ''
      this.damage.turret.label_a = ''
      this.damage.turret.label_b = ''
      this.damage.turret.diff = 0
      this.damage.turret.diffa = 0

      this.damage.monster.a = ''
      this.damage.monster.label_a1 = ''
      this.damage.monster.label_a2 = ''
      this.damage.monster.label_a3 = ''

      this.damage.monster.b = ''
      this.damage.monster.label_b1 = ''
      this.damage.monster.label_b2 = ''
      this.damage.monster.label_b3 = ''
      this.damage.monster.label_a = ''
      this.damage.monster.label_b = ''
      this.damage.monster.diff = 0
      this.damage.monster.diffa = 0

      this.btnSummit = false;

    },
    reuseValue() {
      this.damage.dealth.label_a = ''
      this.damage.dealth.label_b = ''
      this.damage.dealth.diff = 0
      this.damage.dealth.diffa = 0

      this.damage.taken.label_a = ''
      this.damage.taken.label_b = ''
      this.damage.taken.diff = 0
      this.damage.taken.diffa = 0

      this.damage.turret.label_a = ''
      this.damage.turret.label_b = ''
      this.damage.turret.diff = 0
      this.damage.turret.diffa = 0

      this.damage.monster.label_a = ''
      this.damage.monster.label_a1 = ''
      this.damage.monster.label_a2 = ''
      this.damage.monster.label_a3 = ''
      this.damage.monster.label_b = ''
      this.damage.monster.label_b1 = ''
      this.damage.monster.label_b2 = ''
      this.damage.monster.label_b3 = ''
      this.damage.monster.diff = 0
      this.damage.monster.diffa = 0
      this.btnSummit = false;
    },
    play() {
      this.$refs.player.play();
      this.setPlaying(true);
    },
    pause() {
      this.$refs.player.pause();
      this.setPlaying(false);
    },
    setPlaying(state) {
      this.playing = state;
    },
    mute() {
      this.$refs.player.muted = true;
      this.bgVideo.muted = true
    },
    unmute() {
      this.$refs.player.muted = false;
      this.bgVideo.muted = false
    },
    changeBgColor() {
      this.bgImage = null
    },
    changeDealth() {
      let value = 0;
      const a = this.damage.dealth.a ? parseInt(this.damage.dealth.a) : 0;
      const b = this.damage.dealth.b ? parseInt(this.damage.dealth.b) : 0;
      if (a || b) {
        value = this.calculatePercent(a, b);
      }

      let count_a = 1;
      let count_b = 1;
      let label_a = setInterval(() => {
        count_a = ++count_a * this.speed;
        if (count_a < a) {
          this.damage.dealth.label_a = count_a;
        } else {
          this.damage.dealth.label_a = a;
          clearInterval(label_a)
        }
      }, 30);
      let label_b = setInterval(() => {
        count_b = ++count_b * this.speed;
        if (count_b < b) {
          this.damage.dealth.label_b = count_b;
        } else {
          this.damage.dealth.label_b = b;
          clearInterval(label_b)
        }
      }, 30);

      this.damage.dealth.diff = value.toFixed();
      this.damage.dealth.diffa = 100 - value.toFixed()

    },
    changeTaken() {
      let value = 0;
      let a = this.damage.taken.a ? parseInt(this.damage.taken.a) : 0;
      let b = this.damage.taken.b ? parseInt(this.damage.taken.b) : 0;
      if (a || b) {
        value = this.calculatePercent(a, b);
      }
      let count_a = 1;
      let count_b = 1;
      let label_a = setInterval(() => {
        count_a = ++count_a * this.speed;
        if (count_a < a) {
          this.damage.taken.label_a = count_a;
        } else {
          this.damage.taken.label_a = a;
          clearInterval(label_a)
        }
      }, 30);
      let label_b = setInterval(() => {
        count_b = ++count_b * this.speed;
        if (count_b < b) {
          this.damage.taken.label_b = count_b;
        } else {
          this.damage.taken.label_b = b;
          clearInterval(label_b)
        }
      }, 30);

      this.damage.taken.diff = value.toFixed();
      this.damage.taken.diffa = 100 - value.toFixed()

    },
    changeTurret() {
      let value = 0;
      let a = this.damage.turret.a ? parseInt(this.damage.turret.a) : 0;
      let b = this.damage.turret.b ? parseInt(this.damage.turret.b) : 0;
      if (a || b) {
        value = this.calculatePercent(a, b);
      }
      let count_a = 1;
      let count_b = 1;
      let label_a = setInterval(() => {
        count_a = ++count_a * this.speed;
        if (count_a < a) {
          this.damage.turret.label_a = count_a;
        } else {
          this.damage.turret.label_a = a;
          clearInterval(label_a)
        }
      }, 30);
      let label_b = setInterval(() => {
        count_b = ++count_b * this.speed;
        if (count_b < b) {
          this.damage.turret.label_b = count_b;
        } else {
          this.damage.turret.label_b = b;
          clearInterval(label_b)
        }
      }, 30);

      this.damage.turret.diff = value.toFixed();
      this.damage.turret.diffa = 100 - value.toFixed()
    },
    changeMonster() {
      let value = 0;
      let a1 = this.damage.monster.a1 ? parseInt(this.damage.monster.a1) : 0;
      let a2 = this.damage.monster.a2 ? parseInt(this.damage.monster.a2) : 0;
      let a3 = this.damage.monster.a3 ? parseInt(this.damage.monster.a3) : 0;
      this.damage.monster.a = a1 + a2 + a3;
      let a = this.damage.monster.a;
      let b1 = this.damage.monster.b1 ? parseInt(this.damage.monster.b1) : 0;
      let b2 = this.damage.monster.b2 ? parseInt(this.damage.monster.b2) : 0;
      let b3 = this.damage.monster.b3 ? parseInt(this.damage.monster.b3) : 0;
      this.damage.monster.b = b1 + b2 + b3;
      let b = this.damage.monster.b;
      if (a || b) {
        value = this.calculatePercent(a, b);
      }
      let count_a = 1;
      let count_b = 1;
      let label_a = setInterval(() => {
        count_a = ++count_a * this.speed;
        if (count_a < a) {
          this.damage.monster.label_a = count_a;
        } else {
          this.damage.monster.label_a = a;
          clearInterval(label_a)
        }
      }, 30);
      let label_b = setInterval(() => {
        count_b = ++count_b * this.speed;
        if (count_b < b) {
          this.damage.monster.label_b = count_b;
        } else {
          this.damage.monster.label_b = b;
          clearInterval(label_b)
        }
      }, 30);

      let count_a1 = 1;
      let label_a1 = setInterval(() => {
        count_a1 = ++count_a1 * this.speed;
        if (count_a1 < a1) {
          this.damage.monster.label_a1 = parseInt(count_a1);
        } else {
          this.damage.monster.label_a1 = a1;
          clearInterval(label_a1)
        }
      }, 30);
      let count_a2 = 1;
      let label_a2 = setInterval(() => {
        count_a2 = ++count_a2 * this.speed;
        if (count_a2 < a2) {
          this.damage.monster.label_a2 = parseInt(count_a2);
        } else {
          this.damage.monster.label_a2 = a2;
          clearInterval(label_a2)
        }
      }, 30);
      let count_a3 = 1;
      let label_a3 = setInterval(() => {
        count_a3 = ++count_a3 * this.speed;
        if (count_a3 < a3) {
          this.damage.monster.label_a3 = parseInt(count_a3);
        } else {
          this.damage.monster.label_a3 = a3;
          clearInterval(label_a3)
        }
      }, 30);

      let count_b1 = 1;
      let label_b1 = setInterval(() => {
        count_b1 = ++count_a1 * this.speed;
        if (count_b1 < b1) {
          this.damage.monster.label_b1 = parseInt(count_b1);
        } else {
          this.damage.monster.label_b1 = b1;
          clearInterval(label_b1)
        }
      }, 30);
      let count_b2 = 1;
      let label_b2 = setInterval(() => {
        count_b2 = ++count_b2 * this.speed;
        if (count_b2 < b2) {
          this.damage.monster.label_b2 = parseInt(count_b2);
        } else {
          this.damage.monster.label_b2 = b2;
          clearInterval(label_b2)
        }
      }, 30);
      let count_b3 = 1;
      let label_b3 = setInterval(() => {
        count_b3 = ++count_b3 * this.speed;
        if (count_b3 < b3) {
          this.damage.monster.label_b3 = parseInt(count_b3);
        } else {
          this.damage.monster.label_b3 = b3;
          clearInterval(label_b3)
        }
      }, 30);

      this.damage.monster.diff = value.toFixed();
      this.damage.monster.diffa = 100 - value.toFixed()

    },
    calculatePercent(a, b) {
      let max = a > b ? a : b;
      let total = a + b;
      return (max / total) * 100;
    },
    buttonClose() {
      window.close();
      // app.quit();
    },
    submitStats() {
      this.changeDealth();
      this.changeTaken();
      this.changeTurret();
      this.changeMonster();
      this.btnSummit = true;
    },
    resetSetting()
    {
      this.resetBg('all');
    },
    verify()
    {
      if (this.passcode === 'happygaming123') {
        this.lock = false;
      } else {
        this.incorrect = true;
      }
    }
  },
  filters: {
    nn(val) {
      let a = val ? parseInt(val) : 0;
      return a.toLocaleString();
    },
  },
}
</script>
